<template>
    <div class="box">
      <Breadcrumb class="navigation">
        <BreadcrumbItem v-for="item in breadMenuData"
          :to="item.path">
          {{ item.name }}
        </BreadcrumbItem>
      </Breadcrumb>
      <div class="contact-box">
          <p class="tit">{{ $t('key1000047') }}</p>
          <p>{{ $t('key1005626') }}</p>
          <p>{{ $t('key1005627') }}</p>
          <p>{{ $t('key1005628') }}</p>
      </div>
    </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';

export default {
  name: 'contactUs',
  mixins: [Mixin],
  data () {
    return {
      breadMenuData: [{
        path: '/index',
        name: alias47916751af154eb5b47cd5de9d34633d.t('key1005493')
      },{
        path: '/contactUs',
        name: alias47916751af154eb5b47cd5de9d34633d.t('key1000044')
      }]
    };
  },
  created () {

  },
  props: {},
  watch: {},
  methods: {}
};
</script>

<style scoped lang="less">
.box {
  width: 1200px;
  margin: 0 auto;

  .navigation {
    padding: 10px;
  }
  .contact-box {
    background-color: #ffffff;
    margin-bottom: 20px;
    padding: 24px 32px 24px 32px;
    line-height: 32px;
    min-height: 500px;
    color: #000;
    .tit {
      font-weight: bold;
    }
  }
}
</style>
